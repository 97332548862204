import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactNode, useEffect } from "react";

import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import SectionTitle from "../../../molecules/section-title/SectionTitle";
import { DealEventProperties } from "../../../organisms/deal-event-properties/domain/deal-event-properties";
import { useDealEventProperties } from "../../../organisms/deal-event-properties/use-cases/deal-event-properties-use-cases";
import { Result, UncoveredPetReason } from "../../../organisms/result/domain/result";
import { useResult } from "../../../organisms/result/use-cases/result-use-cases";
import { PageId } from "../../../settings/pages";
import { PetSpecies } from "../../../settings/pet";
import { Events, track } from "../../../utils/analytics";
import * as styles from "../../Uncovered.module.scss";

const dogUncoveredSVG: string = require("../../../images/pages/results/uncovered/dog.svg").default;
const catUncoveredSVG: string = require("../../../images/pages/results/uncovered/cat.svg").default;

const petSpeciesImages: { [key in keyof typeof PetSpecies]: string } = {
  cat: catUncoveredSVG,
  dog: dogUncoveredSVG,
};

const Page = (): JSX.Element => {
  const result = useResult() as Result;
  const { t } = useTranslation();
  const dealEventProperties: DealEventProperties | null = useDealEventProperties();

  let message: ReactNode;

  switch (result.uncoveredReason) {
    case UncoveredPetReason.PET_TOO_OLD:
      message = parse(t("pg_uncovered.message.pet_too_old", { petName: result.pet_name }));
      break;
    case UncoveredPetReason.HEALTH_QUOTE_MISSING:
    default:
      message = parse(t("pg_uncovered.message.uncovered_breed", { petName: result.pet_name }));
      break;
  }

  useEffect(() => {
    if (dealEventProperties) {
      track(Events.UNCOVERED_PET, {
        ...dealEventProperties,
        uncoveredReason: result.uncoveredReason,
      });
    }
  }, [dealEventProperties, result.uncoveredReason]);

  return (
    <>
      {result && (
        <section className={styles.card}>
          <img
            className={styles.image}
            src={petSpeciesImages[result.pet_species]}
            alt=""
            width={88}
            height={88}
          />
          <SectionTitle title={t("pg_uncovered.title", { petName: result.pet_name })} />
          <div className={styles.message}>{message}</div>
          <Link to={`/${result?.country.toLowerCase()}`} className={styles.link}>
            {t("pg_uncovered.cta.text")}
          </Link>
        </section>
      )}
    </>
  );
};

export default withFunnelWrapper(Page, "pg-uncovered", PageId.uncovered);

export const query = graphql`
  query UncoveredQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
